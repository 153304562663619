import styled from 'styled-components';
import { AiOutlineWarning } from 'react-icons/ai'

export const Description = styled.p`
  font-size: 1.8rem;

  @media(max-width: 375px){
    font-size: 1.4rem;
  }
`

export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
`

export const WarningIcon = styled(AiOutlineWarning)`
  width: 100px;
  height: 100px;
`

export const TextInfo = styled.p`
  font-size: 1.8rem;
  text-align: left;
  padding: 0px 0px 30px 0px;
  margin: -6px;
`