import styled from 'styled-components';
import { ImCancelCircle } from 'react-icons/im'
import { FiCheckCircle } from 'react-icons/fi'
import { MdCall } from 'react-icons/md';

export const Description = styled.p`
  font-size: 1.8rem;

  @media(max-width: 375px){
    font-size: 1.4rem;
  }
`

export const Title = styled.p`
  font-size: 2.2rem;
  font-weight: bold;
`

export const Title2 = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
`

export const WrapperTextInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TextInfo = styled.p`
  font-size: 1.8rem;
  text-align: left;
  padding: 0px 0px 30px 0px;
  margin: -6px;
`

export const TextInfo2 = styled.p`
  font-size: 1.8rem;
  text-align: center;
  padding: 0px 0px 0px 0px;
`

export const CancelIcon = styled(ImCancelCircle)`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`

export const ConfirmIcon = styled(FiCheckCircle)`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`

export const CentralWrapper = styled.div`
  display: flex;
  text-align: center;
`

export const CallIcon = styled(MdCall)`
  margin-right: 10px;
`