import React, { FC, ReactNode, useState } from 'react';
import { confirmSms } from '../../services/spec-portin.service';
import { useHistory } from 'react-router-dom'
import Loading from 'react-loading';

import {
  Logo,
  WrapperResponse,
  WrapperInfo,
  Accordion
} from '../../components';

import {
  Title,
  AccordionBody,
  AccordionHead,
  ResponseButton,
  WrapperButtons,
  AccordionText,
  CheckIcon,
  CallIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  WrapperLoad
} from './styles';

type Props = {
  children?: ReactNode;
  location?: {
    state: {
      Response: {
        token: string,
        data: {
          urlLogo: string
        }
      }
    }
  }
}

const PaginaCancelamento: FC = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const token = props.location?.state.Response.token;

  const handleSubmit = async (confirmation: number) => {
    setLoading(true)
    const res = await confirmSms({ token: (token || ''), confirmation })
    setLoading(false)

    if (res.data.erro) {
      history.push('/falha', { Response: { ...res.data, confirmation, data: props.location?.state.Response.data } })
    } else {
      history.push('/sucesso', { Response: { ...res.data, confirmation, data: props.location?.state.Response.data } })
    }
  }


  return (
    <>
      <Logo src={props.location?.state.Response.data?.urlLogo} onClick={() => history.push(`/${token}`)} />
      <WrapperResponse>
        <Title>
          Você tem certeza que deseja cancelar o seu pedido de Portabilidade?
        </Title>
        <WrapperButtons>
          {
            loading ?
              <WrapperLoad>
                <Loading color={"black"} width={"32px"} />
              </WrapperLoad>
              :
              <>
                <ResponseButton confirm onClick={() => handleSubmit(0)}>
                  SIM
                </ResponseButton>
                <ResponseButton onClick={() => history.push(`/${token}`)}>
                  NÃO
                </ResponseButton>
              </>
          }
        </WrapperButtons>
      </WrapperResponse>
      <WrapperInfo>
        <h3><strong>Dúvidas sobre portabilidade?</strong></h3>
        <AccordionHead onClick={() => setOpen(!open)}>Saiba mais sobre os benefícios {open ? <ArrowUpIcon /> : <ArrowDownIcon />} </AccordionHead>
        <Accordion active={open} >
          <AccordionBody>
            <AccordionText>
              <div>
                <CheckIcon />
              </div>
              <p>Ao solicitar a portabilidade você <b>continua utilizando o número que já está acostumado</b>, com os benefícios do seu novo plano.</p>
            </AccordionText>
            <AccordionText>
              <div>
                <CheckIcon />
              </div>
              <p>Caso se arrependa, o cancelamento do pedido pode ser feito em até 48h</p>
            </AccordionText>
          </AccordionBody>
        </Accordion>

        <AccordionHead onClick={() => setOpen2(!open2)}>Condições para realizar a portabilidade {open2 ? <ArrowUpIcon /> : <ArrowDownIcon />}</AccordionHead>
        <Accordion active={open2} >
          <AccordionBody>
            <AccordionText>
              <div>
                <CheckIcon />
              </div>
              <p>Para que a portabilidade tenha sucesso você deve ter ativado seu novo chip com o mesmo CPF cadastrado na operadora antiga</p>
            </AccordionText>
            <AccordionText>
              <div>
                <CheckIcon />
              </div>
              <p>Ambas as linhas devem estar ativas e vinculadas ao mesmo DDD</p>
            </AccordionText>
            <AccordionText>
              <div>
                <CheckIcon />
              </div>
              <p>Caso se arrependa, o cancelamento do pedido pode ser feito em até 48h</p>
            </AccordionText>
          </AccordionBody>
        </Accordion>

        <AccordionHead onClick={() => setOpen3(!open3)}>Fale com nosso time e tire suas dúvidas {open3 ? <ArrowUpIcon /> : <ArrowDownIcon />}</AccordionHead>
        <Accordion active={open3} >
          <AccordionBody>
            <AccordionText>
              <p>Ficou em dúvida sobre a Portabilidade? Fale com a gente!</p>
            </AccordionText>
            <AccordionText>
              <div>
                <CallIcon />
              </div>
              <p>Central de Atendimento *288 ou 10543</p>
            </AccordionText>
          </AccordionBody>
        </Accordion>
      </WrapperInfo>
    </>
  );
}

export default PaginaCancelamento;
