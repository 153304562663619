import styled from 'styled-components';
import { BsCheckCircle } from 'react-icons/bs';
import { MdCall } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

type ButtonProps = {
  confirm?: boolean
}

export const Title = styled.h2`
  font-size: 2.4rem;
  padding-bottom: 5px;
  font-weight: 400;
;

  @media(max-width: 800px){
    font-size: 1.8rem;
  }
`

export const AccordionHead = styled.button`
  width: 100%;
  height: 50px;
  box-shadow: 
  2px 5px 4px rgb(0 0 0 / 11%),
  -2px -4px 4px rgb(0 0 0 / 11%)
  ;
  border: 0px;
  border-radius: 5px;
  padding: 10px 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -4px;
  font-size: 1.4rem;
  margin-top: 24px;
`

export const AccordionBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 4px;
  height: auto;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 11%),
  -2px 0px rgb(0 0 0 / 11%)
  ;
  border: 0px;
  border-top: 0px;
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 20px 32px;
  text-align: left;
`

export const AccordionText = styled.div`
  display: flex;
  margin-top: 10px;
`

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 10px;

  @media(max-width: 425px){
    width: 90%;
  }
`

export const WrapperLoad = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ResponseButton = styled.button`
  width: 100%;
  max-width: 80px;
  height: 40px;
  border-radius: 5px;
  font-size: 2.0rem;
  border: ${(props: ButtonProps) => props.confirm ? '0px' : '1px solid black'};
  color: ${(props: ButtonProps) => props.confirm ? 'white' : 'black'};
  background-color: ${(props: ButtonProps) => props.confirm ? '#0071AE' : '#eefe'};

  @media(max-width: 425px){
    font-size: 1.5rem;
  }
`


export const CheckIcon = styled(BsCheckCircle)`
  margin-right: 10px;
`

export const CallIcon = styled(MdCall)`
  margin-right: 10px;
`

export const ArrowDownIcon = styled(IoIosArrowDown)`
  margin-right: 10px;
`

export const ArrowUpIcon = styled(IoIosArrowUp)`
  margin-right: 10px;
`