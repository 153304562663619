import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  height: auto;
  box-shadow: 
  2px 5px 4px rgb(0 0 0 / 11%),
  -2px -4px 4px rgb(0 0 0 / 11%)
  ;
`

export const CardHeader = styled.div`
  width: 100%;
  background-color: gray;
  border-radius: 10px 10px 0px 0px;
  color: white;
  padding: 10px 10px;
`

export const CardBody = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 25px;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
`

export const Text = styled.p`
  font-size: 1.6rem;
`

export const WrapperDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 20px;
`

export const ConfirmButton = styled.button`
  width: 100%;
  max-width: 200px;
  height: 3em;
  border: none;
  border-radius: 10px;
  font-size: 2.0rem;
  font-weight: bold;
  margin-bottom: 30px;

  @media(max-width: 800px){
    font-size: 1.8rem;
    margin-top: 0rem;
  }

  background-color: #78ce2b;

  &:hover {
    transition: 1s;
    opacity: 0.8;
  }
`

export const CancelButton = styled.button`
  border: 0;
  border-bottom: 1px solid;
  background: none;
  font-size: 1.2rem;
  font-weight: bold;
  align-self: flex-end;
  justify-self: end;
  margin-right: 10px;
`