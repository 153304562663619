import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
	width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
`