import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom'

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import {
  Logo,
  WrapperResponse,
} from '../../components'

import {
  Description,
  Title,
  Title2,
  TextInfo,
  TextInfo2,
  CancelIcon,
  ConfirmIcon,
  CallIcon,
} from './styles';

import WrapperInfo from './WrapperInfo'

type Props = {
  children?: ReactNode;
  location?: {
    state: {
      Response: {
        confirmation: number;
        token: string;
        data: {
          urlLogo: string;
        }
      }
    }
  }
}

const PaginaConfirmacao: FC = (props: Props): JSX.Element => {
  //@ts-ignore

  let history = useHistory();

  const confirmacao = props.location?.state.Response.confirmation;
  console.log(confirmacao)

  const token = props.location?.state.Response.token;

  const resposta = Boolean(confirmacao && confirmacao === 1);

  return (
    <>
      <Logo src={props.location?.state.Response.data?.urlLogo} onClick={() => history.push(`/${token}`)} />
      <WrapperResponse>
        {
          resposta ?
            <>
              <ConfirmIcon />
              <Description>
                Confirmação de portabilidade realizada com sucesso!
              </Description>
            </>
            :
            <>
              <CancelIcon />
              <Description>
                Cancelamos o seu pedido de Portabilidade!
              </Description>
            </>
        }

      </WrapperResponse>
      <WrapperInfo>
        {
          resposta ?
            <>
              <Title>
                Informações importantes
              </Title>
              <Timeline>
                <TimelineItem>
                  <TimelineOppositeContent style={{ flex: 0.1 }}></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ color: 'black', backgroundColor: 'black' }} />
                    <TimelineConnector style={{ color: 'black', backgroundColor: 'black' }} />
                  </TimelineSeparator>
                  <TimelineContent><TextInfo>
                    O prazo para a portabilidade é de 3 a 5 dias úteis
                  </TextInfo></TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent style={{ flex: 0.1 }}></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ color: 'black', backgroundColor: 'black' }} />
                  </TimelineSeparator>
                  <TimelineContent><TextInfo>
                    O cancelamento do pedido de portabilidade pode ser realizado em até 48h
                  </TextInfo></TimelineContent>
                </TimelineItem>
              </Timeline>
            </>
            :
            <>
              <Title2>
                Caso não tenha solicitado a Portabilidade do seu número, favor desconsiderar.
              </Title2>
              <Title2>
                Ficou em dúvida sobre a portabilidade? Fale com a gente!
              </Title2>
              <TextInfo2>
                <CallIcon /> Central de Atendimento *288 ou 10543
              </TextInfo2>
            </>
        }
      </WrapperInfo>
    </>
  );
}

export default PaginaConfirmacao;
