import React, { FC, WebViewHTMLAttributes } from 'react';
// import { Wrapper } from './styles'; 

import { StyledWrapper } from './styles';

const WrapperResponse: FC<WebViewHTMLAttributes<HTMLDivElement>> = ({children}) => {
  return <StyledWrapper>
    {children}
  </StyledWrapper>;
};

export default WrapperResponse;
