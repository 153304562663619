export const celphoneMask = (number: number) => {
  let stringNumber = number.toString();

  stringNumber=stringNumber.substr(2);
  stringNumber=stringNumber.replace(/^(\d{2})(\d)/g,"($1) $2");
  stringNumber=stringNumber.replace(/(\d)(\d{4})$/,"$1 $2");

  return stringNumber;
}

export const cpfMask = (value: string) => {
  return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value: string) => {
  return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\/\d{4})(\d)/, '$1-$2') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
      .replace(/(-\d{2})(\d)/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
