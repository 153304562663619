import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 
  0px 5px 4px rgb(0 0 0 / 11%),
  0px -4px 4px rgb(0 0 0 / 11%)
  ;
  border: 0px;
  border-radius: 8px;
  height: 200px;
  width: 100%;
  max-width: 500px;
  padding: 3.2rem;
`