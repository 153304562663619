import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom'

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import {
  Logo,
  WrapperResponse,
} from '../../components'

import {
  Description,
  WarningIcon,
  TextInfo
} from './styles';

import WrapperInfo from './WrapperInfo'

type Props = {
  children?: ReactNode;
  location?: {
    state: {
      Response: {
        descricao: string;
        erro: number;
        token: string,
        data: {
          urlLogo: string;
        }
      }
    }
  }
}

const Falha: FC = (props: Props): JSX.Element => {

  let history = useHistory();

  let descricaoErro = null;

  const token = props.location?.state.Response.token;

  if (props.location?.state !== undefined && props.location?.state.Response.erro === 158) {
    descricaoErro = props.location.state.Response.descricao;
  }

  return (
    <>
      <Logo src={props.location?.state.Response.data?.urlLogo} onClick={() => history.push(`/${token}`)} />
      <WrapperResponse>
        <WarningIcon />
        <Description>
          {
            descricaoErro ?
              descricaoErro
              :
              "Ocorreu uma falha na solicitação de portabilidade. Tente novamente mais tarde."
          }!
        </Description>
      </WrapperResponse>
      <WrapperInfo>
        <>
          <Timeline>
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: 0.1 }}></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={{ color: 'black', backgroundColor: 'black' }} />
                <TimelineConnector style={{ color: 'black', backgroundColor: 'black' }} />
              </TimelineSeparator>
              <TimelineContent><TextInfo>
                <b>Não conseguimos efetivar o seu pedido de Portabilidade!</b>
              </TextInfo></TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: 0.1 }}></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={{ color: 'black', backgroundColor: 'black' }} />
                <TimelineConnector style={{ color: 'black', backgroundColor: 'black' }} />
              </TimelineSeparator>
              <TimelineContent><TextInfo>
                Para que a portabilidade tenha sucesso é preciso que <u>ambas as linhas estejam no mesmo DDD, ativas e vinculadas ao mesmo CPF</u>
              </TextInfo></TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: 0.1 }}></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={{ color: 'black', backgroundColor: 'black' }} />
              </TimelineSeparator>
              <TimelineContent><TextInfo>
                <u>Confira as informações de cadastro</u> do seu chip e solicite novamente a Portabilidade em alguns instantes!
              </TextInfo></TimelineContent>
            </TimelineItem>
          </Timeline>
        </>
      </WrapperInfo>
    </>
  );
}

export default Falha;
