import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import {Container, Wrapper} from './components'

import {
  Confirmacao,
  PaginaCancelamento,
  Sucesso,
  Falha
} from './pages';

function App(props: any): JSX.Element {
  return (
    <Router>
      <Container>
        <Wrapper>
          <Switch>
            <Route path="/sucesso" component={Sucesso} />
            <Route path="/cancelar" component={PaginaCancelamento} />
            <Route path="/falha" component={Falha} />
            <Route path="/:token" component={Confirmacao} />
          </Switch>
        </Wrapper>
      </Container>
    </Router>
  );
}

export default App;
