import React, { FC, WebViewHTMLAttributes } from 'react';
// import { Wrapper } from './styles'; 

import { StyledContainer } from './styles';

const Container: FC<WebViewHTMLAttributes<HTMLDivElement>> = ({children}) => {
  return <StyledContainer>
    {children}
  </StyledContainer>;
};

export default Container;
