import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_PORTABILIDADE_SMS_URL
const API_KEY = process.env.REACT_APP_API_PORTABILIDADE_SMS_API_KEY

const baseURL: string | undefined = BASE_URL

// console.log(baseURL)

const request = axios.create({
	baseURL,
	timeout: 20000
})

export function getPortabilidadeSmsByToken(token: string) {
	return request.get(`/portabilidade/${token}`, {
		headers: {
			"x-api-key": API_KEY
		}
	})
}