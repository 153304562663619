import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { confirmSms } from '../../services/spec-portin.service';
import { getPortabilidadeSmsByToken } from '../../services/portabilidade-sms.service';
import moment from 'moment';
import Loading from 'react-loading';
import { celphoneMask, cnpjMask, cpfMask } from '../../utils/helpers/mascaras';

import {
  Logo,
} from '../../components'

import {
  Card,
  CardHeader,
  CardBody,
  Text,
  Title,
  WrapperDate,
  WrapperButtons,
  ConfirmButton,
  CancelButton,
} from './styles';

type Params = {
  token: string;
}

type PortabilityDataType = {
  coPortabilidade: number;
  nuDocumento: string;
  noCliente: string;
  nuMsisdn: number;
  nuMsisdnOutraOperadora: number;
  dtCadastro: Date;
  coMvno: number;
  noMvno: string;
  urlLogo: string;
}

const PaginaConfirmacao: FC = (props): JSX.Element => {
  const [data, dataSet] = useState<PortabilityDataType | null>(null)
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { token }: Params = useParams();

  let history = useHistory();

  const handleCancelPortability = async () => {
    history.push('/cancelar', { Response: { token, data } })
  }

  const handleSubmit = async (confirmation: number) => {
    setLoading(true)
    const res = await confirmSms({ token, confirmation })
    setLoading(false)

    if (res.data.erro) {
      history.push('/falha', { Response: { token, ...res.data, confirmation, data } })
    } else {
      history.push('/sucesso', { Response: { token, data, confirmation } })
    }
  }

  useEffect(() => {
    async function fetchData() {
      let { data } = await getPortabilidadeSmsByToken(token);
      console.log('data', data)
      dataSet(data.data)
      setDataLoaded(true)
    }

    fetchData()
  }, [token]);

  return (
    <>
      {dataLoaded &&
        <>
          <Logo src={data?.urlLogo} onClick={() => history.push(`/${token}`)} />
          <Card>
            <CardHeader>
              <h2>Confirmação de Processo de Portabilidade</h2>
            </CardHeader>
            <CardBody>
              <Title>Nome</Title>
              <Text>{data?.noCliente}</Text>

              <Title>CPF</Title>
              <Text>{data?.nuDocumento && String(data?.nuDocumento).length === 11 ? cpfMask(data?.nuDocumento as string) : cnpjMask(data?.nuDocumento as string)}</Text>

              <WrapperDate>
                <div>
                  <Title>Data</Title>
                  <Text>{moment(data?.dtCadastro).utc().format('DD/MM/YYYY')}</Text>
                </div>
                <div>
                  <Title>Hora</Title>
                  <Text>{moment(data?.dtCadastro).utc().format('HH:mm')}</Text>
                </div>
              </WrapperDate>

              <Title>Número Novo Chip</Title>
              <Text>{data?.nuMsisdnOutraOperadora && celphoneMask(data.nuMsisdnOutraOperadora)}</Text>

              <Title>Número Atual</Title>
              <Text>{data?.nuMsisdn && celphoneMask(data.nuMsisdn)}</Text>
              {loading ?
                <WrapperButtons>
                  <Loading height={"32px"} width={"32px"} color={"green"} />
                </WrapperButtons>
                :
                <WrapperButtons>
                  <ConfirmButton type="submit" onClick={() => handleSubmit(1)}>
                    Confirmar Pedido
                  </ConfirmButton>
                  <CancelButton type="submit" onClick={() => handleCancelPortability()} >
                    Cancelar Solicitação
                  </CancelButton>
                </WrapperButtons>
              }
            </CardBody>
          </Card>
        </>
      }
    </>
  );
}

export default PaginaConfirmacao;
