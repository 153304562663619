import axios from 'axios'

const BASE_URL = process.env.REACT_APP_SPEC_PORTIN_URL

const baseURL: string | undefined = BASE_URL

// console.log(baseURL)

const request = axios.create({
	baseURL,
	timeout: 20000
})

export function confirmSms(params: {token: string, confirmation: number }) {
	return request.post('/v1/portabilidade/portin/confirmar/sms', params)
}